import { computed } from 'vue'
import requestDomain from '../../../../../../../../domains/requestDomain'

const useLegends = () => {
  const getRequestDomain = computed(() => {
    return requestDomain
  })
  return {
    getRequestDomain
  }
}

export default useLegends
